<template>
  <section class="Content-Page">
    
    <div class="mb-3">
      <div class="row">
        <div class="col-md-4 col-5">
          <div class="input-group search-field">
            <div class="input-group-prepend bg-transparent">
              <i class="input-group-text border-0 mdi mdi-magnify"></i>
            </div>
            <input
              v-model="searchKey"
              type="text"
              v-on:keyup="search()"
              placeholder="Search By Order"
              class="form-control bg-transparent border-0 search-border-left"
            />
          </div>
        </div>
        <div class="col-md-8 col-7">
          <div class="float-right">
            <a class="btn btn btn-add btn-success" @click="downloadFile()">Export</a>
            <router-link class="btn btn btn-add btn-primary" :to="`/order/new-order`"
              >Add New
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-6 col-md-3 pr-0">
                <multiselect
                  @input="sortByOrderStatus"
                  v-model="status"
                  :options="orderStatus"
                  placeholder="Select Order Status"
                  label="name"
                ></multiselect>
              </div>
              <div class="col-6 col-md-3 pr-0">
                <multiselect
                  @input="sortByOrderPaymentMode"
                  v-model="payment_mode"
                  :options="paymentMode"
                  placeholder="Select Payment Mode"
                  label="name"
                ></multiselect>
              </div>

              <div class="col-1 col-md-2"></div>
              <div class="col-md-4 col-11 pl-0">
                <form @submit.prevent="sortByAmountRange">
                  <div class="row">
                    <div class="col-4 col-md-4 pr-0 pl-3">
                      <input
                        type="text"
                        v-model="amountRange.amount_from"
                        class="form-control pr-1 pl-2"
                        placeholder="Amount from"
                      />
                    </div>
                    <div class="col-4 col-md-4 pr-2 pl-2">
                      <input
                        type="text"
                        v-model="amountRange.amount_to"
                        class="form-control pr-1 pl-2"
                        placeholder="Amount To"
                      />
                    </div>
                    <div class="col-4 col-md-4 pl-0">
                      <button
                        class="btn btn btn-add btn-dark"
                        style="width: 100%; padding: 1px; border-radius: 0px"
                      >
                        <i class="mdi mdi-magnify" style="font-size: 22px !important"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>User</th>
                  <th>Status</th>
                  <th>Payment Mode</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Billing</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="order">
                <tr v-for="(item, index) in order" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.users.first_name }}</td>
                  <td>{{ item.statuses[0].name }}</td>
                  <td>{{ item.payment_mode.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.mobile_number }}</td>
                  <td>{{ item.billing_details.address_1 }}</td>
                  <td>
                    {{
                      item.amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </td>
                  <td>
                    <router-link
                      class="mr-2 text-info"
                      :to="`/order/edit-order/${item.id}`"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </router-link>
                   <a class="text-primary mr-2" @click="downloadPdf()"> <i class="mdi mdi-printer font-size-18"></i></a>
                   

                    <!-- <router-link :to="`{item.invoice}`" class="text-primary mr-2">
                      <i class="mdi mdi-printer font-size-18"></i>
                    </router-link> -->
                    <a v-on:click="deleteFile(item.id)" class="text-dark">
                      <i class="mdi mdi-window-close font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="order == ''">No data found!</div>
          <div style="border-top: 1px solid #e9ecef" v-if="order">
            <paginate  :page-count="totalPage" :page-range="3" :margin-pages="1"
              :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { orderApi, setingsApi } from "../../api";

import Multiselect from "vue-multiselect";
import Paginate from 'vuejs-paginate'
export default {
  name: "orders",
  components: {
    Paginate,
    Multiselect,
  },
  data() {
    return {
      order: null,
      
      totalPage: this.totalPage,
      orderStatus: [],
      a: "",
      searchKey: "",
      status: [],
      payment_mode: "",
      paymentMode: [],
      amountRange: {
        amount_from: "",
        amount_to: "",
      },
    };
  },
  mounted() {
    let page = 1;
    this.getOrderList(page);
    this.getOrderStatus();
    this.getPaymentModes();
  },

  methods: {
    async downloadFile() {
      
      var parameter = {
        status: this.status.id,
        payment_mode: this.payment_mode.id,
        amount_from: this.amountRange.amount_from,
        amount_to: this.amountRange.amount_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var exportParam = new URLSearchParams(parameter).toString();
      const { data } = await orderApi.orderExport(exportParam);
      const blob = new Blob([data.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.setAttribute("download", "orders.xlsx");
      link.href = URL.createObjectURL(blob);
      link.click();
      
    },
    async downloadPdf() {
      const response = 'https://shoplattice-api.codestager.com/public/storage/Invoice-shoplattice-2022-08-16%2014:07:59.pdf'
      const blob = new Blob([response]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "test.pdf";
      link.click();
    },
    
    async getOrderList(page) {
      // let b, c;
      
      const { data } = await orderApi.orders(page);
      this.order = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
      // this.orderStatus.forEach((val) => {
      //   b = val.id;
      //   c = val.name;
      // });
      // this.order.forEach((element) => {
      //   this.a = element.status_id;

      //   if (this.a == b) {
      //     this.a = c;
      //   }
      // });
    },
    clickCallback: function (page) {
      this.getOrderList(page) 
    },
    deleteFile(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = orderApi.delete(id).then((response) => {
            
            if (response.data.code == 200) {
              this.getOrderList(1);
              if (result.value) {
                this.$swal("Deleted!", "Your file has been deleted.", "success");
              }
            }
          });
        }
      });
    },
    async getOrderStatus() {
      
      const { data } = await orderApi.status();
      this.orderStatus = data.data.data;
      this.orderStatus.push({
        id: 0,
        name: "All Statuses ",
      });
      
    },
    async getPaymentModes() {
      
      const { data } = await setingsApi.paymentModes();
      this.paymentMode = data.data.data;
      this.paymentMode.push({
        id: 0,
        name: "All Payment Mode ",
      });
      
    },
    async sortByOrderStatus(page) {
      if (this.status && this.status.id == 0) {
        this.getOrderList(page);
      }
       else {
        var parameter = {
          search: this.searchKey,
          status: this.status.id,
          payment_mode: this.payment_mode.id,
          amount_from: this.amountRange.amount_from,
          amount_to: this.amountRange.amount_to,
        };
        var text = Object.keys(parameter).forEach(
          (k) =>
            (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
        );
        var statusParam = new URLSearchParams(parameter).toString();
        page = 1;
        
        const { data } = await orderApi.sortByStatus(statusParam, page);
        this.order = data.data.data.data;
        
        this.totalPage = data.data.data.last_page;
        this.current_page = data.data.data.current_page;
      }
    },
    async sortByOrderPaymentMode(page) {
      if (this.payment_mode && this.payment_mode.id == 0) {
        this.getOrderList(page);
      }
      else {
        var parameter = {
          search: this.searchKey,
          status: this.status.id,
          payment_mode: this.payment_mode.id,
          amount_from: this.amountRange.amount_from,
          amount_to: this.amountRange.amount_to,
        };
        var text = Object.keys(parameter).forEach(
          (k) =>
            (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
        );
        var paymentParam = new URLSearchParams(parameter).toString();
        page = 1;
        
        const { data } = await orderApi.sortByPaymentMode(paymentParam, page);
        this.order = data.data.data.data;
        
        this.totalPage = data.data.data.last_page;
        this.current_page = data.data.data.current_page;
      }
    },
    async sortByAmountRange(page) {
      var parameter = {
        search: this.searchKey,
        status: this.status.id,
        payment_mode: this.payment_mode.id,
        amount_from: this.amountRange.amount_from,
        amount_to: this.amountRange.amount_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var rangeParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await orderApi.amountRange(rangeParam, page);
      this.order = data.data.data.data;
      
    },
    async search(page) {
      var parameter = {
        search: this.searchKey,
        status: this.status.id,
        payment_mode: this.payment_mode.id,
        amount_from: this.amountRange.amount_from,
        amount_to: this.amountRange.amount_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var rangeParam = new URLSearchParams(parameter).toString();
      page = 1;

      
      const { data } = await orderApi.searchByData(rangeParam, page);
      this.order = data.data.data.data;
      
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
